export const pageView = function () {
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const value = parameters.get('fbclid');

    if (value) {
        fetch('https://admin.w3vitals.com/wp-json/myplugin/v1/getInfo')
            .then(response => response.json())
            .then((data) => {
                fetch('https://graph.facebook.com/v10.0/186235183249094/events/?access_token=EAAB56ZCrMsZAEBADl5xgwZA1rirZC5hJQQOXnQtdZCk2SDQjlXeX2zYmv2TM2Wi8YDxdvXeNiunyrJ4Vx4kuhImUEHePhRxn2cfasrbwuVhFvaiiCkQ4aoQPsNrIcZBkZA3plTzHfnqajLRZCsLKwHkuBRbHPdsVbVQpOdpvYd5KYY8YYBFBMG9XjCoMe6xfw90ZD', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        'data': [
                            {
                                "event_name": "PageView",
                                "event_id": "IwAR10rll3vLaR0CtUoxq2xw8NjfbZtMiJrZO5jIVPd1l_0bcyFAH9JqheWf0",
                                "event_source_url": window.location.href,
                                "event_time": Math.floor(new Date().getTime() / 1000),
                                "action_source": "website",
                                "user_data": {
                                    "client_ip_address": data.ip,
                                    "client_user_agent": data.browser
                                },
                            }
                        ]
                    }),
                })
                    .then((res) => res.json())
                    .then((data) => {
                        console.log(data)
                    })
                    .catch((err) => console.log(err));
            });
    }

}