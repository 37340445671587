import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

import FlaticonRight from "../../assets/images/icons/right.svg"

export default function AboutUs(props) {
    return (
        <StaticQuery
            query={graphql`
                query AboutImages {
                    circleShape1: file(relativePath: { eq: "shape/circle-shape1.png" }) {
                        childImageSharp {
                            fixed(width: 350 , height: 386){
                                base64
                                width
                                height
                                src
                                srcSet
                            }
                        }
                    }
                }
            `}
            render={data => (
                <section className="about-area pb-100">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12 d-none d-md-block">
                                <div className="about-image">
                                    <Img fluid={props.aboutUs.image.imageFile.childImageSharp.fluid} alt={props.aboutUs.image.altText} />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="about-content">
                                    <div className="content">
                                        <div className="justify-content-center justify-content-md-start" style={{display: "flex", alignItems: "center"}}>
                                            <span className="sub-title" style={{marginBottom: "0px",color:"rgb(163, 44, 0)"}}>{props.aboutUs.subtitle}</span>
                                        </div>
                                        <h2>{props.aboutUs.title}</h2>
                                        <p>{props.aboutUs.paragraph}</p>

                                        <ul className="features-list">
                                            {props.aboutUs.values.map((value, i) => (
                                                <li key={`about-value-${i}`}>
                                                    <Img fixed={value.image.imageFile.childImageSharp.fixed} alt={value.image.altText} />
                                                    <h3 style={{fontSize: "1.4rem", whiteSpace: "pre-line"}}>{value.title}</h3>
                                                    <p>{value.subtitle}</p>
                                                </li>
                                            ))}
                                        </ul>
                                        <p>{props.aboutUs.paragraph2}</p>

                                        <Link to={props.lang === "EN" ? `/en/about-us/` : (props.lang === "EL" ?`/etaireia/` : `/fr/about-us/`)} className="default-btn">
                                            <img src={FlaticonRight} className="btn-svg" alt="arrow-right" />{props.lang === "EN" ? "Learn More" : (props.lang === "EL" ?"Περισσότερα" : "Suite") }<span></span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="circle-shape1">
                        <Img fixed={data.circleShape1.childImageSharp.fixed} alt="banner" />
                    </div>
                </section>
            )}
        />
    )
}
