import React, { useEffect, useState } from 'react'
// import seoAnalysis from '../../assets/images/seo-analysis.png'
import axios from 'axios';
import Img from "gatsby-image"
import qs from 'qs'
import FidgetSpinner from "./../../assets/images/fidget-spinner.svg"
import "./../../assets/css/circle.css"
import validUrl from "valid-url"


const AnalysisForm = (props) => {
    const data = props;

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    const [analysisResults, setAnalysisResults] = useState("")

    // useEffect(() => {

    //     var data = qs.stringify({
    //         'url': 'https://stegi-therapeion.gr'
    //     });

    //     var config = {
    //         method: 'post',
    //         url: 'https://node.w3vitals.com/',
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         data: data
    //     };

    //     axios(config)
    //         .then(function (response) {
    //             console.log(response.data)
    //             console.log(JSON.parse(response.data.data));
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });

    // }, [])

    function validateEmail(emailAdress) {
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (emailAdress.match(regexEmail)) {
            return true;
        } else {
            return false;
        }
    }

    function getPageAnalysis() {
        if (!loading) {

            let url = document.getElementById("analysisUrl").value;

            let name = document.getElementById("analysisFullName").value;

            let email = document.getElementById("analysisEmail").value;

            let tele = document.getElementById("analysisTele").value;


            if (!url.includes("https://")) {
                url = "https://" + url
            }

            if (url.includes("http://")) {
                setError("Δοκιμάστε να εισάγετε την σελίδα σας με το πρωτόκολλο https. To http πρωτόκολλο δεν είναι ασφαλές")
                return;
            }

            if (name.length < 3) {
                setError("Προσθέστε το ονοματεπώνυμό σας")
                return;
            }

            if (tele.length < 10) {
                setError("Ο αριθμός δεν είναι έκγυρος")
                return;
            }

            if (!validateEmail(email)) {
                setError("Το email δεν είναι έκγυρο")
                return;
            }

            if (validUrl.isUri(url) && (name.length >= 3) && tele.length >= 10 && validateEmail(email)) {
                setLoading(true)
                setError("")

                var data = {
                    url: url,
                    email: email,
                    name: name,
                    phone_number: tele,
                    lang: props.lang

                };

                console.log(data)

                axios.post(`https://admin.w3vitals.com/wp-json/myplugin/v1/analysisForm`, data)
                    .then(function (response) {
                        setAnalysisResults(response.data)
                        setLoading(false)
                    })
                    .catch(function (error) {
                        setLoading(false)
                        setError("Υπήρξε πρόβλημα με τον διακομιστή, προσπαθήστε ξανά αργότερα")
                        console.log(error);
                    })

            } else {
                setError("Το Url που πληκτρολογήσατε δεν είναι έγκυρο")
            }
        }
    }



    return (
        <div className="seo-analysis-section pb-100 pt-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        {(analysisResults === "" && !loading) && (
                            <div className="analysis-img">
                                <Img fluid={data.analysis.image.imageFile.childImageSharp.fluid} alt={data.analysis.image.altText} className="h-full w-full gScale m-auto" />
                            </div>
                        )}

                        {loading && (
                            <div className="w-full" style={{ display: "flex", justifyContent: "center" }}>
                                <img src={FidgetSpinner} alt="spinner" />
                            </div>
                        )}

                        {analysisResults !== "" && (
                            <div dangerouslySetInnerHTML={{__html: analysisResults}} />
                                

                            // <div className="row">
                            //     {console.log(analysisResults)}

                            //     {Object.keys(analysisResults).map((key, i) => {
                            //         if (analysisResults[key].score) {
                            //             return (
                            //                 <div className="col-6" style={{ justifyContent: "center", display: "flex", flexWrap: "wrap" }}>
                            //                     <div className={`c100 ${`p` + (analysisResults[key].score * 100)} ${analysisResults[key].score >= 0.9 ? "green" : ((analysisResults[key].score <= 0.9 && analysisResults[key].score >= 0.5) ? "orange" : "red")}`}>
                            //                         <span>{analysisResults[key].score * 100}</span>
                            //                         <div className="slice">
                            //                             <div className="bar"></div>
                            //                             <div className="fill"></div>
                            //                         </div>
                            //                     </div>
                            //                     <div style={{ width: "100%", textAlign: "center", fontSize: "28px" }}>
                            //                         {analysisResults[key].title}
                            //                     </div>
                            //                 </div>
                            //             )
                            //         }
                            //     })}
                            //     <div style={{ width: "100%" }}>
                            //         {!analysisResults.seo.score ?
                            //             <p>Φαίνεται, πως η ιστοσελίδα που συμπληρώσατε, δεν υπάρχει!</p>
                            //             :
                            //             (
                            //                 <>
                            //                     {!(analysisResults.seo.score < 1) && (
                            //                         <p style={{ paddingTop: "1rem", marginBottom: "0px" }}>Συγχαρητήρια η ιστοσελίδα σας έχει φτάσει στο ανώτατο όριο στις μετρικές της Lighthouse! Επικοινωνήστε μαζί μας για να σας αποστείλουμε μια πιο αναλυτική SEO αναφορά.<br />
                            //                             Αναλύσαμε την ιστοσελίδα σας, χρησιμοποιώντας το εργαλείο της Lighthouse του Google Chrome.</p>
                            //                     )}
                            //                     {analysisResults.seo.score < 1 && (
                            //                         <p>Φαίνεται πως η ιστοσελίδα σας μπορεί να βελτιωθεί. Επικοινωνήστε μαζί μας για να εκτοξεύσουμε τις μετρικές σας της ιστοσελίδας σας στο 100%.<br />
                            //                             Αναλύσαμε την ιστοσελίδα σας, χρησιμοποιώντας το εργαλείο της Lighthouse του Google Chrome.</p>
                            //                     )}
                            //                 </>
                            //             )
                            //         }
                            //     </div>
                            // </div>

                        )}
                    </div>

                    <div className="col-lg-6">
                        <div className="analysis-form">
                            <span style={{ color: "rgb(163, 44, 0)" }} className="sub-title">{data.analysis.subtitle}</span>
                            <h2>{data.analysis.title}</h2>
                            <p>{data.analysis.content}</p>

                            <div>
                                <div className="row">
                                    {/* {data.analysis.form && data.analysis.form.map((inp, i) => ( */}
                                    {/* key={`analysis-form-${i}`} */}
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label style={{ color: "#6b6b84", fontWeight: "600" }} for={"analysisFullName"} >{props.lang === "EN" ? "Full name" : (props.lang === "FR" ? "Nom et prénom" : "Ονοματεπώνυμο")}</label>
                                            <div style={{ display: "flex" }}>
                                                <input
                                                    id="analysisFullName"
                                                    type={"text"}
                                                    className="form-control"
                                                    style={{
                                                        borderTopLeftRadius: "0px",
                                                        borderBottomLeftRadius: "0px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label style={{ color: "#6b6b84", fontWeight: "600" }} for={"analysisEmail"} >Email</label>
                                            <div style={{ display: "flex" }}>
                                                <input
                                                    id="analysisEmail"
                                                    type={"email"}
                                                    className="form-control"
                                                    style={{
                                                        borderTopLeftRadius: "0px",
                                                        borderBottomLeftRadius: "0px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label style={{ color: "#6b6b84", fontWeight: "600" }} for={"analysisTele"} >{props.lang === "EN" ? "Phone number" : (props.lang === "FR" ? "Téléphone" : "Τηλέφωνο")}</label>
                                            <div style={{ display: "flex" }}>
                                                <input
                                                    id="analysisTele"
                                                    type={"text"}
                                                    className="form-control"
                                                    style={{
                                                        borderTopLeftRadius: "0px",
                                                        borderBottomLeftRadius: "0px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label style={{ color: "#6b6b84", fontWeight: "600" }} for={"analysisUrl"} >{props.lang === "EN" ? "Domain Name" : (props.lang === "FR" ? "Nom de domaine" : "Domain Name Ιστοσελίδας")}</label>
                                            <div style={{ display: "flex" }}>
                                                <span className="https-cl">https://</span>
                                                <input
                                                    id="analysisUrl"
                                                    type={"text"}
                                                    className="form-control"
                                                    placeholder={"w3vitals.com"}
                                                    style={{
                                                        borderTopLeftRadius: "0px",
                                                        borderBottomLeftRadius: "0px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* ))} */}
                                </div>

                                <button onClick={getPageAnalysis} className="default-btn">
                                    {/* <i className="flaticon-tick"></i> */}
                                    <span style={{ position: "absolute", left: "20px" }}>&#10003;</span>
                                    {props.lang === "EN" ? "Apply" : (props.lang === "FR" ? "Appliquer" : "Εκδήλωση ενδιαφέροντος")}
                                    <span></span>
                                </button>
                                {error && (
                                    <p style={{ color: "red" }}>{error}</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnalysisForm;

export const query = graphql`
    fragment FreeAnalysisFragment on WPGraphQL_Template {
        freeAnalysisTemplateAcf {
            title
            subtitle
            content
            cta
            ctaUrl
            image {
                altText
                sourceUrl
                imageFile{
                    childImageSharp {
                        fluid(maxWidth: 585, quality:100){
                            base64
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                        }
                    }
                }
            }
            form{
                input{
                    type
                    label
                    placeholder
                    regex
                    min
                    max
                    minResponse
                    maxResponse
                    regexResponse
                }
            }
        }
    }
`