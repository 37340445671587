import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import FlaticonRefresh from "../../assets/images/icons/refresh.svg"

// import starIcon from '../../assets/images/star-icon.png'

// circleShape1: file(relativePath: { eq: "shape/circle-shape1.png" }) {
//     childImageSharp {
//         fixed(width: 350 , height: 386){
//             base64
//             width
//             height
//             src
//             srcSet
//         }
//     }
// }

export default function DigitalServices(props) {
    return (
        <StaticQuery
            query={graphql`
                query DigitalServices {
                    starIcon: file(relativePath: { eq: "star-icon.png" }) {
                        childImageSharp {
                            fixed(width: 34){
                                base64
                                width
                                height
                                src
                                srcSet
                            }
                        }
                    }
                }
            `}
            render={data => (
                <section className="process-area pt-100 pb-70">
                    <div className="container">
                        <div className="section-title">
                            <span style={{ color: "rgb(163, 44, 0)" }} className="sub-title">
                                {/* <img src={starIcon} alt="banner" /> */}
                                {props.servicesInfo.subtitle}
                            </span>
                            <h2>{props.servicesInfo.title}</h2>
                            <p>{props.servicesInfo.content}</p>
                        </div>

                        <div className="row" style={{justifyContent: "center"}}>
                            {
                                props.services && props.services.edges.map((service, i) => (
                                    <div className="col-lg-4 col-md-6" key={`digital-service-${i}`}>
                                        <Link to={`${service.node.uri}`}>
                                            <div className="single-process-box">
                                                <div className="number">1</div>
                                                <div className="image">
                                                    <Img fixed={service.node.servicesAcf.smallIcon.imageFile.childImageSharp.fixed} alt={service.node.servicesAcf.smallIcon.altText} />
                                                </div>
                                                <h3
                                                    style={{
                                                        minHeight: "60px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {service.node.servicesAcf.titleExcerpt}
                                                </h3>
                                                <div style={{minHeight:"120px"}} dangerouslySetInnerHTML={{ __html: service.node.excerpt }}></div>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                            }

                        </div>

                        {!(props.from && props.from === "services") && (
                            <div className="col-lg-12 col-md-12">
                                <div className="load-more-btn text-center bttn-wh">
                                    <Link to={props.lang === "EN" ? "/en/digital-marketing/" : (props.lang === "EL" ? "/digital-marketing/" : "/fr/digital-marketing/")} className="default-btn" style={{ color: "#A32C00" }}>
                                        <img src={FlaticonRefresh} className="btn-svg" alt="refresh-icon" /> {props.servicesInfo.loadMore} <span></span>
                                    </Link>
                                </div>
                            </div>
                        )}

                    </div>

                    {/* <div className="circle-shape1">
                        <Img fixed={data.circleShape1.childImageSharp.fixed} alt="circle-shape" />
                    </div> */}
                </section>
            )}
        />
    )
}

