import React from 'react'
// import ReactWOW from 'react-wow'
// import ModalVideo from 'react-modal-video'
import { Link } from 'gatsby'
// import Helmet from "react-helmet"

import DigitalMarketing from "../../assets/images/icons/digital-marketing.svg"
import WebSolutions from "../../assets/images/icons/web-solutions.svg"
import LazyHydrate from "react-lazy-hydration";



const MainBanner = (props) => {

    return (
        <React.Fragment>
            {/* <Helmet>
                <link rel="preload" as="image" href={`https://fervent-jennings-a32d07.netlify.app/static/main-banner-bg2-mob-b616826179a841ddd47bbe8659bce1f0.png`} />
            </Helmet> */}
            <div className="banner-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <div className="banner-content">
                                {/* <div delay='.1s' animation='fadeInLeft'> */}
                                <LazyHydrate ssrOnly >
                                    <h1>{props.introductionHeader}</h1>
                                </LazyHydrate>
                                {/* </div> */}

                                {/* <div delay='.1s' animation='fadeInLeft'> */}
                                <p>{props.introductionParagraph}</p>
                                {/* </div> */}

                                <div delay='.1s' animation='fadeInRight'>
                                    <div className="btn-box btn-box-extra">
                                        <Link
                                            to={props.lang === "EN" ? `/en/web-solutions/` : (props.lang === "EL" ? `/web-solutions/` : `/fr/web-solutions/`)}
                                            className="default-btn"
                                        >
                                            <img src={WebSolutions} className="btn-svg" style={{ top: "13px" }} alt="web solutions icon" />
                                            Web Solutions
                                        </Link>

                                        <Link
                                            to={props.lang === "EN" ? `/en/digital-marketing/` : (props.lang === "EL" ? `/digital-marketing/` : `/fr/digital-marketing/`)}
                                            className="default-btn"
                                        >
                                            <img src={DigitalMarketing} className="btn-svg" style={{ top: "13px" }} alt="digital marketing icon" />
                                            Digital Marketing
                                        </Link>

                                        {/* <Link
                                            to="#"
                                            onClick={e => { e.preventDefault(); openModal() }}
                                            className="video-btn popup-youtube"
                                        >
                                            <i className="flaticon-google-play"></i> Watch Video
                                        </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12">
                            <div>
                                <div className="d-none d-md-block d-xl-block" style={{ position: "relative", top: "-100px" }}>
                                    <picture>
                                        <source type="image/webp" srcSet={props.introductionImage.imageFile.childImageSharp.fluid.srcSetWebp} importance="high" />
                                        <source type="image/jpg" srcSet={props.introductionImage.imageFile.childImageSharp.fluid.srcSet} />
                                        <img
                                            alt={props.introductionImage.altText}
                                            style={{ top: "0", left: "0" }}
                                        />
                                    </picture>
                                </div>

                                <div className="d-none">
                                    <picture>
                                        <source type="image/webp" srcSet={props.introductionImage.imageFile.childImageSharp.fluid.srcSetWebp} importance="high" />
                                        <source type="image/jpg" srcSet={props.introductionImage.imageFile.childImageSharp.fluid.srcSet} />
                                        <img
                                            alt={props.introductionImage.altText}
                                            style={{ top: "0", left: "0" }}
                                        />
                                    </picture>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MainBanner
