import React from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import FlaticonRefresh from "../../assets/images/icons/refresh.svg"
import htmlDecode from "html-entities-decoder";


import Img from "gatsby-image"


const RecentProjects = (props) => {
    const data = props;
    return (
        <section className="services-area bg-f1f8fb pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title" style={{ color: " rgb(163, 44, 0)" }}>
                        {data.caseStudies.subtitle}
                    </span>
                    <h2>{data.caseStudies.title}</h2>
                    <p>{data.caseStudies.content}</p>
                </div>

                <div className="row" style={{ justifyContent: "center" }}>
                    {data.caseStudyProjects && data.caseStudyProjects.map((cs, i) => (
                        <div className="col-lg-4 col-md-6" key={`cs-row-${i}`}>
                            { cs.node.caseStudyAcf.isnested
                                ?
                                <Link to={`${cs.node.uri}`}>
                                    <div className="single-projects-box">
                                        <div className="image">
                                            {/* <img src={project1} alt="project" /> */}
                                            <Img fluid={cs.node.caseStudyAcf.sectionImage.imageFile.childImageSharp.fluid} alt={cs.node.caseStudyAcf.sectionImage.altText} className="cs-trans" />

                                            {/* <Link className="link-btn" to={`${cs.node.uri}`}>
                                            <i className='bx bx-plus'></i>
                                        </Link> */}
                                        </div>

                                        <div className="content">
                                            <h3>
                                                <Link to={`${cs.node.uri}`}>
                                                    {htmlDecode(cs.node.title)}
                                                </Link>
                                            </h3>
                                            <span>{cs.node.caseStudyAcf.tag}</span>
                                        </div>
                                    </div>
                                </Link>
                                :
                                <div>
                                    <div className="single-projects-box">
                                        <div className="image">
                                            {/* <img src={project1} alt="project" /> */}
                                            <Img fluid={cs.node.caseStudyAcf.sectionImage.imageFile.childImageSharp.fluid} alt={cs.node.caseStudyAcf.sectionImage.altText} className="cs-trans" />

                                            {/* <Link className="link-btn" to={`${cs.node.uri}`}>
                                            <i className='bx bx-plus'></i>
                                        </Link> */}
                                        </div>

                                        <div className="content">
                                            <h3>
                                                <div>
                                                    {htmlDecode(cs.node.title)}
                                                </div>
                                            </h3>
                                            <span>{cs.node.caseStudyAcf.tag}</span>
                                        </div>
                                    </div>
                                </div>

                            }
                        </div>
                    ))}
                </div>
                <div className="col-lg-12 col-md-12">
                    <div className="load-more-btn text-center bttn-wh">
                        <Link to={props.lang === "EN" ? "/en/customer-stories/" : (props.lang === "EL" ? "/case-studies/" : "/fr/customer-stories/")} className="default-btn" style={{ color: "#A32C00" }}>
                            <img src={FlaticonRefresh} className="btn-svg" alt="refresh-icon" /> { props.lang === "EN" ? "Find out more"  : ( props.lang === "EL" ? "Περισσότερες πληροφορίες" : "En savoir plus")} <span></span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="lines">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
        </section>
    )
}

export default RecentProjects;

export const query = graphql`
    fragment CaseStudiesTemplateFragment on WPGraphQL_Template {
        caseStudyTemplateAcf {
            title
            subtitle
            content
        }
    }

    fragment CaseStudiesFragment on WPGraphQL_RootQueryToCaseStudyConnection {
        edges {
            node {
                title
                uri
                caseStudyAcf {
                    tag
                    featuredImage {
                        sourceUrl
                        imageFile{
                            childImageSharp{
                                fluid{
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    sectionImage {
                        sourceUrl
                        imageFile{
                            childImageSharp{
                                fluid{
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    isnested
                }
            }
        }
    }
`