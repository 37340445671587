import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"

import { pageView } from "../utils/fbApi"

import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import MainBanner from "../components/DataScienceAndMLCompany/MainBanner"
// import OurSolutions from "../components/DataScienceAndMLCompany/OurSolutions"
import AboutUs from "../components/DataScienceAndMLCompany/AboutUs"
import OurServices from "../components/DataScienceAndMLCompany/OurServices"
import DigitalServices from "../components/DataScienceAndMLCompany/DigitalServices"
// import HowItWorks from "../components/DataScienceAndMLCompany/HowItWorks"
import CaseStudySlider from "../components/DataScienceAndMLCompany/CaseStudySlider"
import AnalysisForm from "../components/DataScienceAndMLCompany/AnalysisForm"
// import TeamMembers from "../components/DataScienceAndMLCompany/TeamMembers"
// import Testimonials from "../components/DataScienceAndMLCompany/Testimonials"
// import Partner from "../components/DataScienceAndMLCompany/Partner"
// import OurBlog from "../components/DataScienceAndMLCompany/BlogPost"
// import StartProject from "../components/DataScienceAndMLCompany/StartProject"

const HomeTemplate = (props) => {
    let data = props.data.wpgraphql;
    useEffect(() => {
        pageView()
    }, [])

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://w3vitals.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://w3vitals.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <Layout metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)} >
            <Navbar header={props.pageContext.headerMenu} lang={props.pageContext.language} />
            <MainBanner
                lang={props.pageContext.language}
                introductionMobileImage={data.page.homePageAcf.introductionMobileImage}
                introductionImage={data.page.homePageAcf.introductionImage}
                introductionHeader={data.page.homePageAcf.introductionHeader}
                introductionParagraph={data.page.homePageAcf.introductionParagraph}
                introductionButton={data.page.homePageAcf.introductionButton}
                introductionButtonUrl={data.page.homePageAcf.introductionButtonUrl}
            />
            {/* <OurSolutions 
                title={data.page.homePageAcf.whoWeAre.title}
                subtitle={data.page.homePageAcf.whoWeAre.subtitle}
                content={data.page.homePageAcf.whoWeAre.content}
                solutions={data.page.homePageAcf.whoWeAre.solutions}
            /> */}
            <div style={{ paddingTop: "5rem" }}>
                <AboutUs
                    lang={props.pageContext.language}
                    aboutUs={data.page.homePageAcf.aboutUs}
                />
            </div>

            <OurServices
                lang={props.pageContext.language}
                servicesInfo={data.page.homePageAcf.servicesGroup}
                services={data.services}
            />
            <DigitalServices
                lang={props.pageContext.language}
                servicesInfo={data.page.homePageAcf.digitalServicesGroup}
                services={data.digitalServices}
            />
            {/* <HowItWorks 
                hWorks={data.page.homePageAcf.howItWorks}
            /> */}
            <CaseStudySlider
                lang={props.pageContext.language}
                caseStudies={data.caseStudiesTemplate.caseStudyTemplateAcf}
                caseStudyProjects={data.caseStudies.edges}
            />
            {/* <TeamMembers 
                ourTeamHeader= {data.page.homePageAcf.ourTeamHeader}
                ourTeamSubHeader= {data.page.homePageAcf.ourTeamSubHeader}
                ourTeamParagraph= {data.page.homePageAcf.ourTeamParagraph}
                teamMembers= {data.teamMembers.edges}
            /> */}
            <AnalysisForm
                lang={props.pageContext.language}
                analysis={data.analysisTemplate.freeAnalysisTemplateAcf}
            />

            {/* <Testimonials /> */}
            {/* <Partner />
            <OurBlog />
            <StartProject /> */}
            <Footer footer={props.pageContext.footerMenu} extra={data.footerTemplate.footerTemplateAcf} />
        </Layout>
    )
}

export default HomeTemplate

export const pageQuery = graphql`
    query GET_PAGE($id: ID! , $language: WPGraphQL_LanguageCodeFilterEnum, $analysisTemplateUri: ID!, $caseStudyTemplateUri: ID!, $footerTemplateUri: ID!) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
			    homePageAcf{
                    introductionImage{
                        altText
                        sourceUrl
                        imageFile{
                            childImageSharp {
                                fluid(maxWidth: 793){
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    introductionMobileImage{
                        altText
                        sourceUrl
                        imageFile{
                            childImageSharp {
                                fluid(quality:100){
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    introductionHeader
                    introductionParagraph
                    introductionButton
                    introductionButtonUrl
                    whoWeAre{
                        solutions{
                            title
                            paragraph
                            image{
                                altText
                                sourceUrl
                                imageFile{
                                    childImageSharp {
                                        fixed(width: 65, height:65){
                                            base64
                                            width
                                            height
                                            src
                                            srcSet
                                        }
                                    }
                                }
                            }
                            cta
                            ctaSlug
                        }
                    }
                    aboutUs{
                        title
                        subtitle
                        paragraph
                        paragraph2
                        image {
                            altText
                            sourceUrl
                            imageFile{
                                childImageSharp {
                                    fluid(maxWidth: 1000, quality:100){
                                        base64
                                        aspectRatio
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                        sizes
                                    }
                                }
                            }
                        }
                        values{
                            title 
                            subtitle
                            image{
                                altText
                                sourceUrl
                                imageFile{
                                    childImageSharp {
                                        fixed(width: 58){
                                            base64
                                            width
                                            height
                                            src
                                            srcSet
                                        }
                                    }
                                }
                            }
                        }
                    }
                    servicesGroup{
                        title
                        subtitle
                        content
                        readMore
                        loadMore
                    }
                    digitalServicesGroup{
                        title
                        subtitle
                        content
                        readMore
                        loadMore
                    }

                    howItWorks{
                        title
                        subtitle
                        content
                        image {
                            altText
                            sourceUrl
                            imageFile{
                                childImageSharp {
                                    fluid(maxWidth: 575, quality:100){
                                        base64
                                        aspectRatio
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                        sizes
                                    }
                                }
                            }
                        }
                        steps{
                            title
                            image{
                                altText
                                sourceUrl
                                imageFile{
                                    childImageSharp {
                                        fixed(width: 81){
                                            base64
                                            width
                                            height
                                            src
                                            srcSet
                                        }
                                    }
                                }
                            }
                        }
                    }

                    ourTeamHeader
                    ourTeamSubHeader
                    ourTeamParagraph
                    ourClientsTitle
                }
            }
            serviceCategories(first: 100, where: {language: $language}) {
                edges {
                    node {
                        name
                        slug
                        language{
                            code
                        }
                        services {
                            edges {
                                node {
                                    uri
                                    title
                                    excerpt
                                    servicesAcf{
                                        titleExcerpt
                                        smallIcon{
                                            altText
                                            sourceUrl
                                            imageFile{
                                                childImageSharp {
                                                    fixed(width: 250, quality: 100){
                                                        base64
                                                        width
                                                        height
                                                        src
                                                        srcSet
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            services (first: 100, where: {language: $language , orderby: {field: MENU_ORDER, order: ASC}}){
                edges {
                    node {
                        uri
                        title
                        excerpt
                        servicesAcf{
                            titleExcerpt
                            smallIcon{
                                altText
                                sourceUrl
                                imageFile{
                                    childImageSharp {
                                        fixed(width: 250, quality: 100){
                                            base64
                                            width
                                            height
                                            src
                                            srcSet
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            digitalServices (first: 100, where: {language: $language , orderby: {field: MENU_ORDER, order: ASC}}){
                edges {
                    node {
                        uri
                        title
                        excerpt
                        servicesAcf{
                            titleExcerpt
                            smallIcon{
                                altText
                                sourceUrl
                                imageFile{
                                    childImageSharp {
                                        fixed(width: 250, quality: 100){
                                            base64
                                            width
                                            height
                                            src
                                            srcSet
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            teamMembers(first: 10, where: {language: $language, orderby: {field: MENU_ORDER, order: ASC}}) {
                ...OurTeamFragment
            }

            caseStudiesTemplate: template(id: $caseStudyTemplateUri, idType: SLUG) {
                ...CaseStudiesTemplateFragment
            }

            analysisTemplate: template(id: $analysisTemplateUri, idType: SLUG) {
                ...FreeAnalysisFragment
            }

            caseStudies(where: {language: $language}, first: 9) {
                ...CaseStudiesFragment
            }

            footerTemplate: template(id: $footerTemplateUri, idType: SLUG) {
                footerTemplateAcf{
                    addressTitle
                    addressContent
                    content
                }
            }
        }
    }
`