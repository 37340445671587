import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

import FlaticonRight from "../../assets/images/icons/right-orange.svg"
import FlaticonRefresh from "../../assets/images/icons/refresh.svg"
// import W3Gif from '../../assets/images/gifs/w3-gif.gif'



export default function OurServices(props) {
    return (
        <StaticQuery
            query={graphql`
                query OurServices {
                    circleShape2: file(relativePath: { eq: "shape/circle-shape2.png" }) {
                        childImageSharp {
                            fixed(width: 350 , height: 520){
                                base64
                                width
                                height
                                src
                                srcSet
                            }
                        }
                    }
                }
            `}
            render={data => (
                <section className="services-area ptb-100 bg-f1f8fb">
                    <div className="container">
                        <div className="section-title">
                            {!(props.from && props.from === "services") && (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {/* <img src={W3Gif} alt="banner" style={{width: "46px"}} /> */}
                                    <span className="sub-title" style={{ marginBottom: "0px", color: "rgb(163, 44, 0)", marginTop: "0px" }}>{props.servicesInfo.subtitle}</span>
                                </div>
                            )}
                            {!(props.from && props.from === "services") && (
                                <h2>{props.servicesInfo.title}</h2>
                            )}
                            <p>{props.servicesInfo.content}</p>
                        </div>

                        <div className="row" style={{ justifyContent: "center" }}>
                            {
                                props.services && props.services.edges.map((service, i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6" key={`service-${i}`}>
                                        <Link to={`${service.node.uri}`}>

                                            <div className="single-services-box bttn-wh">
                                                <div className="icon">
                                                    <Img fixed={service.node.servicesAcf.smallIcon.imageFile.childImageSharp.fixed} alt={service.node.servicesAcf.smallIcon.altText} />
                                                </div>
                                                <h3
                                                    style={{
                                                        minHeight: "60px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Link to={`${service.node.uri}`}>
                                                        {service.node.servicesAcf.titleExcerpt}
                                                    </Link>
                                                </h3>
                                                <div style={{ minHeight: "120px" }} dangerouslySetInnerHTML={{ __html: service.node.excerpt }}></div>
                                                <Link to={`${service.node.uri}`} className="read-more-btn">
                                                    {props.servicesInfo.readMore} <img src={FlaticonRight} className="btn-svg" alt="arrow-right" style={{ display: "inline-block", position: "initial" }} />
                                                </Link>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                            }

                            {!(props.from && props.from === "services") && (
                                <div className="col-lg-12 col-md-12">
                                    <div className="load-more-btn text-center bttn-wh">
                                        <Link to={props.lang === "EN" ? "/en/web-solutions/" : (props.lang === "EL" ? "/web-solutions/" : "/fr/web-solutions")} className="default-btn" style={{ color: "#A32C00" }}>
                                            <img src={FlaticonRefresh} className="btn-svg" alt="refresh-icon" /> {props.servicesInfo.loadMore} <span></span>
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="circle-shape2">
                        <Img fixed={data.circleShape2.childImageSharp.fixed} alt="circle-shape" />
                    </div>
                    <div className="lines">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                </section>
            )}
        />
    )
}
